import type { NextPage } from 'next';
import Head from 'next/head';
import { HomeContainer } from '../container';

const Home:NextPage = () =>
  <>
    <Head>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
    </Head>
    <HomeContainer />
  </>
;

export default Home;
